import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'

const TableProducts: [string | [], Dispatch<SetStateAction<string | []>>] = [[], () => ({})]

const products = ''

const ProductContext = React.createContext<[string | [], Dispatch<SetStateAction<string | []>>]>(TableProducts)

const ProductContextProvider: React.FC = ({ children }): JSX.Element => {
	const [table, setTable] = useState<string | []>(products)
	useEffect(() => {
		const tableData = window.sessionStorage.getItem('table')
		if (tableData === null) {
			window.sessionStorage.setItem('table', JSON.stringify(table))
			window.sessionStorage.setItem('residential', JSON.stringify(table))
			window.sessionStorage.setItem('commercial', JSON.stringify(table))
			window.sessionStorage.setItem('mineral-swim', JSON.stringify(table))
			window.sessionStorage.setItem('ozone-swim', JSON.stringify(table))
		} else {
			setTable(JSON.parse(tableData))
		}
	}, [])
	return <ProductContext.Provider value={[table, setTable]}>{children}</ProductContext.Provider>
}

export { ProductContext, ProductContextProvider }
