import React, { createContext, useReducer } from 'react'
const isBrowser = typeof window !== 'undefined'
const localStorage = isBrowser && window.localStorage
const hasDark =
	isBrowser &&
	localStorage &&
	(localStorage.theme === 'dark' ||
		(!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches))
if (hasDark) localStorage && localStorage.setItem('theme', hasDark ? 'dark' : 'light')
const initialState = {
	isDark: hasDark ? 'dark' : 'light',
	isMobile: false,
	isSearch: false,
	isSettings: false,
}
export type State = {
	isDark: string
	isMobile: boolean
	isSearch: boolean
	isSettings: boolean
}
export type Action =
	| { type: 'DARK' }
	| { type: 'MOBILE'; isMobile: boolean | null }
	| { type: 'SEARCH'; isSearch: boolean | null }
	| { type: 'SETTINGS'; isSettings: boolean | null }
const reducer = (prevState: State, action: Action): State => {
	switch (action.type) {
		case 'DARK':
			localStorage && localStorage.setItem('theme', prevState.isDark === 'dark' ? 'light' : 'dark')
			return {
				...prevState,
				isDark: prevState.isDark === 'dark' ? 'light' : 'dark',
			}
		case 'MOBILE':
			return {
				...prevState,
				isMobile: action.isMobile !== null ? action.isMobile : !prevState.isMobile,
			}
		case 'SEARCH':
			return {
				...prevState,
				isSearch: action.isSearch !== null ? action.isSearch : !prevState.isSearch,
			}
		case 'SETTINGS':
			return {
				...prevState,
				isSettings: action.isSettings !== null ? action.isSettings : !prevState.isSettings,
			}
		default:
			return prevState
	}
}
const UIContext = createContext<[State, React.Dispatch<Action>]>([initialState, () => ({})])
const UIProvider: React.FC = ({ children }) => {
	const [UI, dispatchUI] = useReducer(reducer, initialState)
	return <UIContext.Provider value={[UI, dispatchUI]}>{children}</UIContext.Provider>
}

export { UIContext, UIProvider }
